export {}
// import React, { Suspense } from 'react'
// import ReactDOM from 'react-dom'
// import { BrowserRouter } from 'react-router-dom'
// import { ThemeProvider } from 'emotion-theming'
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

// import { theme } from 'ui/theme'
// import App from './app'

// import 'assets/normalize.css'
// import 'assets/fonts.css'
// import 'assets/main.css'

// ReactDOM.render(
//   <React.StrictMode>
//     <ThemeProvider theme={theme}>
//       <BrowserRouter>
//         <GoogleReCaptchaProvider
//           useEnterprise
//           reCaptchaKey='6Ld8ZlwhAAAAAKlPBBrPhOlVBk3m45mhuJyS2Ny1'
//         >
//           <Suspense fallback='loading'>
//             <App />
//           </Suspense>
//         </GoogleReCaptchaProvider>
//       </BrowserRouter>
//     </ThemeProvider>
//   </React.StrictMode>,
//   document.getElementById('root'),
// )
